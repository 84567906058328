import { frontendURL } from '../../../../helper/URLHelper';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';
import store from '../../../../store';

const SettingsWrapper = () => import('../SettingsWrapper.vue');
const IntegrationHooks = () => import('./IntegrationHooks.vue');
const Index = () => import('./Index.vue');
const Webhook = () => import('./Webhooks/Index.vue');
const DashboardApps = () => import('./DashboardApps/Index.vue');
const Slack = () => import('./Slack.vue');
const SettingsContent = () => import('../Wrapper.vue');

const integrationFeatureFlags = {
  webhook: FEATURE_FLAGS.WEBHOOK,
  dashboard_apps: FEATURE_FLAGS.DASHBOARD_APPS,
  slack: FEATURE_FLAGS.SLACK_INTEGRATION,
  openai: FEATURE_FLAGS.OPENAI,
  dialogflow: FEATURE_FLAGS.DIALOGFLOW,
  google_translate: FEATURE_FLAGS.GOOGLE_TRANSLATE,
  dyte: FEATURE_FLAGS.DYTE,
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: SettingsWrapper,
      props: {},
      children: [
        {
          path: '',
          name: 'settings_applications',
          component: Index,
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'dashboard_apps',
          component: DashboardApps,
          name: 'settings_integrations_dashboard_apps',
          meta: {
            permissions: ['administrator'],
          },
          beforeEnter: (to, _, next) => {
            const featureFlag = integrationFeatureFlags.dashboard_apps;
            const accountId = store.getters.getCurrentAccountId;

            if (featureFlag && !store.getters['accounts/isFeatureEnabledonAccount'](accountId, featureFlag)) {
              next({ name: 'settings_applications' });
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_integrations';
        const backUrl =
          params.name === 'settings_integrations_integration'
            ? { name: 'settings_integrations' }
            : '';
        return {
          headerTitle: 'INTEGRATION_SETTINGS.HEADER',
          icon: 'flash-on',
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: 'webhook',
          component: Webhook,
          name: 'settings_integrations_webhook',
          meta: {
            permissions: ['administrator'],
          },
          beforeEnter: (to, _, next) => {
            const featureFlag = integrationFeatureFlags.webhook;
            const accountId = store.getters.getCurrentAccountId;

            if (featureFlag && !store.getters['accounts/isFeatureEnabledonAccount'](accountId, featureFlag)) {
              next({ name: 'settings_applications' });
            } else {
              next();
            }
          },
        },
        {
          path: 'slack',
          name: 'settings_integrations_slack',
          component: Slack,
          meta: {
            permissions: ['administrator'],
          },
          props: route => ({ code: route.query.code }),
          beforeEnter: (to, _, next) => {
            const featureFlag = integrationFeatureFlags.slack;
            const accountId = store.getters.getCurrentAccountId;

            if (featureFlag && !store.getters['accounts/isFeatureEnabledonAccount'](accountId, featureFlag)) {
              next({ name: 'settings_applications' });
            } else {
              next();
            }
          },
        },
        {
          path: ':integration_id',
          name: 'settings_applications_integration',
          component: IntegrationHooks,
          meta: {
            permissions: ['administrator'],
          },
          props: route => ({
            integrationId: route.params.integration_id,
          }),
          beforeEnter: (to, _, next) => {
            const integrationId = to.params.integration_id;
            const featureFlag = integrationFeatureFlags[integrationId];
            const accountId = store.getters.getCurrentAccountId;

            if (featureFlag && !store.getters['accounts/isFeatureEnabledonAccount'](accountId, featureFlag)) {
              next({ name: 'settings_applications' });
            } else {
              next();
            }
          },
        },
      ],
    },
  ],
};

